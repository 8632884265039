import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from 'src/app/models/store';
import {environment} from 'src/environments/environment'
 
@Injectable({
  providedIn: 'root'
})
export class StoreService {
 
  currentStore$: Observable<Store>;
  
  private currentStoreSubject = new BehaviorSubject<Store>({storeNumber:0});
  private store: Store | undefined;
 
  constructor() {
    const storeJson = localStorage.getItem(environment.storestorage);
    if(storeJson) {
      this.store=JSON.parse(storeJson);
      if(this.store!==undefined)
        this.currentStoreSubject.next(this.store);
    }
    this.currentStore$ = this.currentStoreSubject.asObservable();
  }
 
  changeStore(newStore: Store): void {
    localStorage.setItem(environment.storestorage,JSON.stringify(newStore));
    this.currentStoreSubject.next(newStore);
    this.store = newStore;
  }

  getCurrentStore(): Store | undefined {
    return this.store;
  }

}