import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, TimeoutError, catchError, concatMap, delay, finalize, from, map, of, throwError, timeout } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Store } from 'src/app/models/store';
import { Fkey } from 'src/app/models/fkeyResponse';
import { QueuedScan} from 'src/app/models/queuedScan';
import { CreditResponse } from 'src/app/models/creditResponse'
import { ConfigurationService } from '../configuration/configuration.service';
import 'src/app/pipes/encrypt-decrypt';
import parseDuration  from 'parse-duration';

@Injectable({
  providedIn: 'root',
})
export class RedemptionService {

  public result$: Observable<boolean | undefined>;
  private resultSubject = new BehaviorSubject<boolean | undefined>(undefined);
  private loadingSubject = new Subject<boolean>();
  private queuedScans: QueuedScan[]= [];
  private localStorageName = 'queuedItems';
  private apiTimeout = parseDuration(this.configService.configData?.ApiTimeOut ?? '15 seconds') ?? 15 * 1000 //default 15s;
  private maxRetries = Number(this.configService.configData?.MaxRetries);

  //CONSTRUCTOR
  constructor ( private http: HttpClient,  private configService: ConfigurationService) {  
    this.result$ = this.resultSubject.asObservable();
  }

  getLoading(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }


  //get new function key, results will be handled in setup module
  getFunctionKey(storeNumber: string | undefined, token: string): Observable<Fkey> {
      return this.http.get<Fkey>('/api/getKey?storeNumber=' + storeNumber, 
        {headers: new HttpHeaders({'Authorization': 'Bearer ' + token})
      });
  }

  healthCheck(storeNumber: string | undefined, mulesoftCheck: boolean, showLoading: boolean): void {
     if(showLoading) {
      this.loadingSubject.next(true);
     }
     this.http.get<boolean>(`/api/healthCheck?storeNumber=${storeNumber}&mulesoftCheck=${mulesoftCheck}`).pipe(timeout(this.apiTimeout),
      finalize(() => {
        if(showLoading) {
          this.loadingSubject.next(false); //notify done
        }
      }),
      map((response: any) => {
        this.resultSubject.next(true);
      }),
      catchError((error: any) => {
        console.debug(error);
        this.resultSubject.next(false);
        return [];
      })
     ).subscribe();
  }

  resetHealthCheck() {
    this.resultSubject.next(undefined);
  }

  getAllStores(accessToken: string): Observable<Store[] | undefined> {
    this.loadingSubject.next(true); // Notify start
    return this.http.get<Store[]>('/api/allStores',
      { headers: new HttpHeaders({'Authorization': 'Bearer ' + accessToken})}).pipe(
      finalize(() => {
        this.loadingSubject.next(false); //notify done
      }),
      map((stores: any[]) => {
        return stores.map(store => ({ storeNumber: store.Number }));
      }),
      catchError((error: any) => {
        let errorMessage = 'Unknown error!';
        if (error.error instanceof ErrorEvent) {
          // Client-side errors
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // Server-side errors
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.error(errorMessage);
        return of(undefined);
      })
    );
  }

//attempt to call credit burn mulesoft api, return true if successfull, false if failed
//if call timed out or server is down, it will return successfull but will be queued to be retried again later.
  creditBurn(loyaltyID: string,siteNumber: string, showLoading: boolean = true): Observable<boolean>  {
    console.debug(`creditBurn called for loyaltyID ${loyaltyID} siteNumber ${siteNumber}`);
    let url = `/api/requestCreditBurn?loyaltyID=${loyaltyID}&siteNumber=${siteNumber}&onlineRequest=${showLoading}`;
    if(this.configService.configData?.FunctionKey===null) {
      console.error("Function Key not set.");
    } else {
        url+=`&code=${this.configService.configData?.FunctionKey}`
    }
    if(showLoading) {
      this.loadingSubject.next(true); 
    }
    return this.http.get<CreditResponse>(url).pipe(timeout(this.apiTimeout),
      finalize(() => {
        if(showLoading) {
          this.loadingSubject.next(false);
        }
      }),
      map((response: CreditResponse) => {
        console.debug('Success: '+response);
        return true;
      }),
      catchError((error: HttpErrorResponse) => {
        let success = false; 
        console.error('Redemption Error Code:'+error);
        if(error.error instanceof TimeoutError ||
           error.error instanceof ErrorEvent ||
           !navigator.onLine) {
          // Client-side errors
          //queue for later retry if showloading is false
          if(showLoading===true) {
            const storedData = localStorage.getItem(this.localStorageName)?.unlock();

            this.queuedScans = storedData ? JSON.parse(storedData) : [];
            this.queuedScans.push({loyaltyID,siteNumber,retries:0});
            localStorage.setItem(this.localStorageName,JSON.stringify(this.queuedScans).lock());
            success = true; //retry when online later
          }
        } else {
          // Server-side errors
          if(showLoading===false && error.status === 422) {
            success = true; //for a queued item, ignore insufficent funds, so it is removed from list
            console.debug(`Retry for ${loyaltyID} has insufficient funds and will be removed.`);
          }

        }
        return of(success);
      })
    );
  }
  
//this procedure will take data from local storage, convert it to an array and process each item in the list
//if the credit api call returns with any value, good or bad (as long as it is online), then the item will be
//considered 'processed' and removed from the queue.  There is a delay between trying each item in the queue
  sync(): Observable<void> {
    const storedData = localStorage.getItem(this.localStorageName)?.unlock();
    this.queuedScans = (storedData !== null && storedData !== undefined) ? JSON.parse(storedData) : [];
    let count = this.queuedScans.length;
    console.debug(`Offline Sync Found ${count} items.`);
    
    // Remove items that exceed the maximum retry number
    this.queuedScans = this.queuedScans.filter(item => item.retries <= this.maxRetries);
    localStorage.setItem(this.localStorageName,JSON.stringify(this.queuedScans).lock());
    if(this.queuedScans.length < count) {
      console.debug(`Removed ${count-this.queuedScans.length} items from queue which exceeded retries of ${this.maxRetries}`)
      count = this.queuedScans.length;//set new count
    }
    return new Observable<void>((observer) => {
      from(this.queuedScans).pipe(
        concatMap((item: QueuedScan) => {
          return this.creditBurn(item.loyaltyID, item.siteNumber, false).pipe(
            map(result => ({ queuedScan: item, success: result })),
            delay(count <= 1 ? 0 : 5000), // Add a 5s delay, but not if on last item in queue
          );
        }),
      finalize(() => {
        observer.complete();
      })
      ).subscribe(resultScan => {
        count = count - 1;
        if(resultScan.success===true) {
          //remove from list
          const index = this.queuedScans.indexOf(resultScan.queuedScan);
          if(index >=0) {
            console.debug('Removing item at index:'+index);
            this.queuedScans.splice(index,1);
            localStorage.setItem(this.localStorageName,JSON.stringify(this.queuedScans).lock());

          }
        } else {
          console.debug(`Item with ID:${resultScan.queuedScan.loyaltyID} failed and will be retried next time. Retries:${resultScan.queuedScan.retries}`);
          resultScan.queuedScan.retries = (resultScan.queuedScan.retries || 0) + 1;//inc retries, even if undefined
          localStorage.setItem(this.localStorageName,JSON.stringify(this.queuedScans).lock());
        }
      });
    });
  }
}