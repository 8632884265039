import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'app-keypad',
  templateUrl: './keypad.component.html',
  styleUrls: ['./keypad.component.scss']
})
export class KeypadComponent implements OnInit {
  @Input() correctPin!: string;
  @Output() correctPinEvent = new EventEmitter<void>();

  pinInput = '';
  isVisible = false;
  keys: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];//available digits
  inactivityTimeout: NodeJS.Timeout | undefined;
  pinLen = 0;

  ngOnInit(): void {
    this.pinLen = this.correctPin.length;
  }

  showKeypad(): void {
    console.debug('PIN',this.correctPin);
    this.isVisible = true;
    this.pinInput = ''; 
   
    this.startTimer(); 
  }

  handleKeyClick(key: number): void {
    this.pinInput += key;
    this.startTimer(); 
    if (this.pinInput.length === this.pinLen) {
      this.checkPin();
    }
  }

  checkPin(): void {
    if (this.pinInput === this.correctPin) {
        this.correctPinEvent.emit();
      } else {
        console.log('Incorrect PIN');
      }
      this.resetKeypad();
  }

  private resetKeypad(): void {
    this.pinInput = '';
    this.isVisible = false;
    this.clearTimer();
  }
  
  private startTimer(): void {
        this.clearTimer();
        this.inactivityTimeout = setTimeout(() => {
        this.resetKeypad();
        }, 4000); // hide after 4 seconds
    }

    private clearTimer(): void {
        if (this.inactivityTimeout) {
            clearTimeout(this.inactivityTimeout);
        }
    }
}


