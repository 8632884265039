import { Injectable, inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { StoreService } from 'src/app/services/store/store.service';
import { ConfigurationService } from '../services/configuration/configuration.service';

@Injectable({
  providedIn: 'root'
})
class StoreGuardService {
  constructor(private router: Router, private storeService: StoreService, private configService: ConfigurationService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const store = this.storeService.getCurrentStore();
    if(store===undefined || !store.storeNumber || this.configService.configData?.FunctionKey === null) {
        this.router.navigate(['/setup']);
        return false;
    }
    return true;
  }
}

export const StoreGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(StoreGuardService).canActivate(next, state);
};
